// src/CareerPage.jsx
import React, { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import "../Career/CareerPage.scss";
import PageHeading from "../PageHeading";
import Spacing from "../Spacing";
import Div from "../Div";
import Cta from "../Cta";
import Loader from "../Loader/loader";

const CareerPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [userData, setUserData] = useState({
    fullName: "",
    email: "",
    College: "",
    mobile: "",
    yop: "",
    cv: "", // Store base64 string instead of the file
  });

  let name, value;
  const postUserData = (event) => {
    name = event.target.name;
    if (name === "cv") {
      const file = event.target.files[0];
      convertToBase64(file).then((base64) => {
        setUserData({ ...userData, [name]: base64 });
      });
    } else {
      value = event.target.value;
      setUserData({ ...userData, [name]: value });
    }
  };

  // Function to convert the file to a base64 string
  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const submitData = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    const { fullName, email, College, mobile, yop, cv } = userData;

    if (fullName && email && mobile && College && yop && cv) {

       // Check if email or mobile number already exists
       const existingDataRes = await fetch(
        "https://nextin-technology-default-rtdb.firebaseio.com/userCareer.json"
      );
      const existingData = await existingDataRes.json();

      // Filter through existing data to find matching email or mobile
      const emailExists = Object.values(existingData).some(
        (user) => user.email === email
      );
      const mobileExists = Object.values(existingData).some(
        (user) => user.mobile === mobile
      );

      if (emailExists || mobileExists) {
        alert("You have already submitted your application.");
        setIsLoading(false);
        return; // Stop further processing
      }

      const res = await fetch(
        "https://nextin-technology-default-rtdb.firebaseio.com/userCareer.json",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            fullName,
            email,
            College,
            mobile,
            yop,
            cv, // Send the base64 string
          }),
        }
      );

      if (res.ok) {
        await fetch("https://email-sender-peach.vercel.app/api/hello", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            from: '"No-Reply" <no-reply@nextintech.in>',
            to: email,
            bcc: "hr@nextintech.in",
            subject: "Job Application Submitted Successfully",
            html: `<!DOCTYPE html>
                  <html lang="en">
                  <head>
                      <meta charset="UTF-8">
                      <meta name="viewport" content="width=device-width, initial-scale=1.0">
                      <title>Application Confirmation</title>
                  </head>
                  <body style="font-family: Arial, sans-serif; margin: 0; padding: 0; background-image: url('https://static.nextintech.in/general/mail_bg.jpg'); background-size: cover; background-position: center;">
                      <div style="max-width: 600px; margin: 50px auto 20px auto; background-color: rgba(255, 255, 255, 0.9); border: 1px solid #dddddd; border-radius: 10px; padding: 20px; box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);">
                          <!-- Header Section -->
                          <div style="background-color: #000; color: #ffffff; padding: 20px; text-align: center; border-radius: 10px 10px 0 0;">
                              <img src="https://static.nextintech.in/logo_trnsparent.png" alt="Company Logo" style="max-width: 200px; margin-bottom: 10px;">
                              <h1 style="margin: 0; font-size: 1.2rem;">Application Confirmation</h1>
                          </div>
                          
                          <!-- Content Section -->
                          <div style="padding: 20px;">
                              <p style="font-size: 16px; line-height: 1.6; color: #333333;">Dear <strong>${fullName}</strong>,</p>
                              <p style="font-size: 16px; line-height: 1.6; color: #333333;">Thank you for applying for the position at <strong>Nextin Technologies</strong>. We have received your application and appreciate your interest in joining our team.</p>
                              <p style="font-size: 16px; line-height: 1.6; color: #333333;">Our hiring team is currently reviewing all applications, and we will get back to you shortly regarding the next steps. In the meantime, if you have any questions or need further information, please feel free to reach out.</p>
                              <p style="font-size: 16px; line-height: 1.6; color: #333333;">Thank you again for considering <strong>Nextin Technologies</strong> as a potential employer. We wish you the best of luck with your application.</p>
                          </div>

                          <!-- Footer Section -->
                          <div style="text-align: center; font-size: 14px; color: #777777; padding: 20px;">
                              <p style="margin: 0;">Best regards,</p>
                              <p style="margin: 5px 0;"><strong>The Talent Acquisition Team</strong><br>
                              <strong>Nextin Technologies</strong><br>
                              Cell: +918824722331</p>
                          </div>
                      </div>
                  </body>
                  </html>
              `,
            attachments: [], // Send the base64 string
          }),
        });
        await fetch("https://email-sender-peach.vercel.app/api/hello", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            from: '"No-Reply" <no-reply@nextintech.in>',
            to: "hr@nextintech.in",
            subject: `Job Application of ${fullName}`,
            attachments: [
              {
                // encoded string as an attachment
                filename: `${fullName}.pdf`,
                content: cv.split("base64,")[1],
                encoding: "base64",
              },
            ],
            html: `<!DOCTYPE html>
                  <html lang="en">
                  <head>
                      <meta charset="UTF-8">
                      <meta name="viewport" content="width=device-width, initial-scale=1.0">
                      <title>Application of ${fullName}</title>
                  </head>
                  <body style="font-family: Arial, sans-serif; margin: 0; padding: 0; background-image: url('https://static.nextintech.in/general/mail_bg.jpg'); background-size: cover; background-position: center;">
                      <div style="max-width: 600px; margin: 50px auto 20px auto; background-color: rgba(255, 255, 255, 0.9); border: 1px solid #dddddd; border-radius: 10px; padding: 20px; box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);">
                          <!-- Header Section -->
                          <div style="background-color: #000; color: #ffffff; padding: 20px; text-align: center; border-radius: 10px 10px 0 0;">
                              <img src="https://static.nextintech.in/logo_trnsparent.png" alt="Company Logo" style="max-width: 200px; margin-bottom: 10px;">
                              <h1 style="margin: 0; font-size: 1.2rem;">Application Confirmation</h1>
                          </div>
                          
                          <!-- Content Section -->
                          <div style="padding: 20px;">
                              <p style="font-size: 16px; line-height: 1.6; color: #333333;">Name: ${fullName},</p>
                              <p style="font-size: 16px; line-height: 1.6; color: #333333;">Email: ${email}</p>
                              <p style="font-size: 16px; line-height: 1.6; color: #333333;">College: ${College}</p>
                              <p style="font-size: 16px; line-height: 1.6; color: #333333;">Mobile: ${mobile}</p>
                              <p style="font-size: 16px; line-height: 1.6; color: #333333;">Year of Passing: ${yop}</p>
                          </div>
                      </div>
                  </body>
                  </html>
              `,
          }),
        });

        setUserData({
          fullName: "",
          email: "",
          College: "",
          mobile: "",
          yop: "",
          cv: "",
        });
        setIsLoading(false);
        alert("Submitted successfully");
        document.querySelector("form").reset();
      }
    } else {
      alert("Please fill all the details!");
      setIsLoading(false);
    }
  };

  return (
    <>
      <PageHeading
        title="Join Our Team"
        bgSrc="https://static.nextintech.in/blog/blog_hero_bg.jpeg"
        pageLinkText="Carrer"
      />
      <Spacing lg="60" md="40" />
      <Div className="container">
        <form action="/submit-form" method="post" className="row">
          <Div className="col-sm-6">
            <label className="cs-primary_color">Full Name*</label>
            <input
              type="text"
              name="fullName"
              required
              className="cs-form_field"
              value={userData.fullName}
              onChange={postUserData}
            />
            <Spacing lg="20" md="20" />
          </Div>
          <Div className="col-sm-6">
            <label className="cs-primary_color">Email*</label>
            <input
              type="email"
              name="email"
              required
              className="cs-form_field"
              value={userData.email}
              onChange={postUserData}
            />
            <Spacing lg="20" md="20" />
          </Div>
          <Div className="col-sm-6">
            <label className="cs-primary_color">College*</label>
            <input
              type="text"
              name="College"
              required
              className="cs-form_field"
              value={userData.College}
              onChange={postUserData}
            />
            <Spacing lg="20" md="20" />
          </Div>
          <Div className="col-sm-6">
            <label className="cs-primary_color">Mobile*</label>
            <input
              type="text"
              name="mobile"
              required
              className="cs-form_field"
              value={userData.mobile}
              onChange={postUserData}
            />
            <Spacing lg="20" md="20" />
          </Div>

          <Div className="col-sm-6">
            <label className="cs-primary_color">Y.O.P*</label>
            <select
              name="yop"
              required
              className="cs-form_field"
              value={userData.yop}
              onChange={postUserData}
            >
              <option
                value=""
                style={{ color: "white", backgroundColor: "#181818" }}
              >
                Select Year
              </option>
              <option
                value="2025"
                style={{ color: "white", backgroundColor: "#181818" }}
              >
                2025
              </option>
              <option
                value="2026"
                style={{ color: "white", backgroundColor: "#181818" }}
              >
                2026
              </option>
              <option
                value="2027"
                style={{ color: "white", backgroundColor: "#181818" }}
              >
                2027
              </option>
              <option
                value="2028"
                style={{ color: "white", backgroundColor: "#181818" }}
              >
                2028
              </option>
            </select>
            <Spacing lg="20" md="20" />
          </Div>

          {/* Add CV upload option */}
          <Div className="col-sm-6">
            <label className="cs-primary_color">Upload CV*</label>
            <input
              type="file"
              name="cv"
              required
              className="cs-form_field"
              onChange={postUserData}
            />
            <Spacing lg="20" md="20" />
          </Div>

          <Div className="col-sm-12">
            <button
              type="submit"
              className="cs-btn cs-style1"
              onClick={submitData}
              disabled={isLoading} // Disable button when loading
            >
              {isLoading ? (
                <Loader /> // Show only the loader when loading
              ) : (
                <>
                  <span>Send</span>
                  <Icon icon="bi:arrow-right" />
                </>
              )}
            </button>
          </Div>
        </form>
        <Spacing lg="145" md="80" />
        <Cta
          title="info@nextintech.in"
          bgSrc="https://static.nextintech.in/general/cta_bg_2.jpeg"
          variant="rounded-0"
        />
      </Div>
    </>
  );
};

export default CareerPage;
