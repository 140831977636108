import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { pageTitle } from "../../helper";
import Button from "../Button";
import Cta from "../Cta";
import PageHeading from "../PageHeading";
import Div from "../Div";
import SectionHeading from "../SectionHeading";
import Spacing from "../Spacing";
import { db } from "../../firebaseconfig/firebase";
import { collection, getDocs } from "firebase/firestore";
import parse from 'html-react-parser';


export default function PortfolioDetailsPage() {
  const params = useParams();
  const page = params.portfolioDetailsId;

  const [portfolioDetails, setportfolioDetails] = useState([]);
  // const[portfolioData,setPortfolioData]=useState([])

  const fetchData = async () => {
    await getDocs(collection(db, "portfolioData")).then((querySnapshot) => {
      const data = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      // setPortfolioData(data[0]?.portfolios)

      // console.log(data[0]?.portfolios);

      for (const key in data[0]?.portfolios) {
        if (data[0]?.portfolios[key]?.href === "/portfolio/" + page) {
          // console.log(data[0]?.portfolios[key])
          setportfolioDetails(data[0]?.portfolios[key]);
        }
      }
    });
  };
  useEffect(() => {
    fetchData();
  }, []);

  // // Inside PortfolioDetailsPage component
  // const [currentIndex, setCurrentIndex] = useState(0);

  // // Function to navigate to the previous project
  // const navigateToPrevProject = () => {
  //   setCurrentIndex((prevIndex) => Math.max(0, prevIndex - 1));
  // };

  // // Function to navigate to the next project
  // const navigateToNextProject = () => {
  //   setCurrentIndex((prevIndex) => Math.min(portfolioData.length - 1, prevIndex + 1));
  // };

  // console.log(params.portfolioDetails)

  pageTitle("Portfolio Details");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <PageHeading
        title="Portfolio Details"
        bgSrc="https://static.nextintech.in/services/service_hero_bg.jpeg"
        // pageLinkText={params.portfolioDetailsId}
        pageLinkText={portfolioDetails?.title}
      />
      <Spacing lg="150" md="80" />
      <Div className="container">
        <img
          src={portfolioDetails?.imgDetail}
          alt="Details"
          className="cs-radius_15 w-100"
        />
        <Spacing lg="90" md="40" />
        <Div className="row">
          <Div className="col-lg-6">
            <SectionHeading
              title={portfolioDetails?.title}
              subtitle={portfolioDetails?.subtitle}
            >
              <Spacing lg="40" md="20" />
              <p>{parse(String(portfolioDetails?.description))}</p>
            </SectionHeading>
          </Div>
          <Div className="col-lg-5 offset-lg-1">
            <Spacing lg="60" md="40" />
            <h2 className="cs-font_30 cs-font_26_sm cs-m0">Project Info -</h2>
            <Spacing lg="50" md="30" />
            <Div className="row">
              <Div className="col-6">
                <h3 className="cs-accent_color cs-font_22 cs-font_18_sm cs-m0">
                  Category:
                </h3>
                <p className="cs-m0">{portfolioDetails?.category}</p>
                <Spacing lg="30" md="30" />
              </Div>
              <Div className="col-6">
                <h3 className="cs-accent_color cs-font_22 cs-font_18_sm cs-m0">
                  Location:
                </h3>
                <p className="cs-m0">{portfolioDetails?.location}</p>
                <Spacing lg="30" md="30" />
              </Div>
              <Div className="col-6">
                <h3 className="cs-accent_color cs-font_22 cs-font_18_sm cs-m0">
                  Software:
                </h3>
                <p className="cs-m0">{portfolioDetails?.software}</p>
                <Spacing lg="30" md="30" />
              </Div>
              {/* <Div className="col-6">
                <h3 className='cs-accent_color cs-font_22 cs-font_18_sm cs-m0'>Dated:</h3>
                <p className='cs-m0'>{portfolioDetails?.dated}</p>
                <Spacing lg='30' md='30'/>
              </Div>
              <Div className="col-6">
                <h3 className='cs-accent_color cs-font_22 cs-font_18_sm cs-m0'>Client:</h3>
                <p className='cs-m0'>{portfolioDetails?.client}</p>
                <Spacing lg='30' md='30'/>
              </Div> */}
              {portfolioDetails?.dated && (
                <Div className="col-6">
                  <h3 className="cs-accent_color cs-font_22 cs-font_18_sm cs-m0">
                    Dated:
                  </h3>
                  <p className="cs-m0">{portfolioDetails.dated}</p>
                  <Spacing lg="30" md="30" />
                </Div>
              )}

              {/* Conditionally render 'Client' if it's available */}
              {portfolioDetails?.client && (
                <Div className="col-6">
                  <h3 className="cs-accent_color cs-font_22 cs-font_18_sm cs-m0">
                    Client:
                  </h3>
                  <p className="cs-m0">{portfolioDetails.client}</p>
                  <Spacing lg="30" md="30" />
                </Div>
              )}
            </Div>
          </Div>
        </Div>
        <Spacing lg="65" md="10" />
        {/* <Div className="cs-page_navigation cs-center">
            <Div>
              <Button btnLink={currentIndex > 0 ? `${portfolioData[currentIndex - 1]?.href}`: ''} btnText='Prev Project' variant='cs-type1' onClick={navigateToPrevProject} disabled={currentIndex === 0}/>
            </Div>
            <Div>
              <Button btnLink={`${portfolioData[currentIndex + 1]?.href}`} btnText='Next Project' onClick={navigateToNextProject} disabled={currentIndex === portfolioData.length - 1}/>
            </Div>
          </Div> */}
      </Div>
      <Spacing lg="145" md="80" />
      <Cta
        title="info@nextintech.in"
        bgSrc="https://static.nextintech.in/general/cta_bg_2.jpeg"
        variant="rounded-0"
      />
    </>
  );
}
