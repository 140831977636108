import React, { useEffect, useState } from 'react'
import Div from '../Div'
import './logolist.scss'
import {db } from "../../firebaseconfig/firebase"; 
import { collection, getDocs } from "firebase/firestore";

export default function LogoList() {




  const[partnerLogos, setpartnerLogos]=useState([]);

  const fetchData = async () => {
       
    await getDocs(collection(db, "partnerLogos"))
        .then((querySnapshot)=>{               
            const data = querySnapshot.docs
                .map((doc) => ({...doc.data(),id:doc.id }));
                // console.log(data[0].caseStudies);
                setpartnerLogos(data[0].logos);                  
        })
   
}
useEffect(()=>{
  fetchData();
  
}, [])


  return (
    <Div style={{ justifyContent: "center" }} className="cs-partner_logo_wrap">
      {partnerLogos.map((partnerLogo, index)=><div className="cs-partner_logo" key={index}><img style={{ maxWidth: "350px" }} src={partnerLogo.src} alt="Partner" /></div>)}
    </Div>
  )
}
